import { Saga } from '@redux-saga/types'
import { all, fork } from 'typed-redux-saga'

import * as certificatesSagas from '@store/certificates/sagas'

import * as orderSagas from './order/sagas'

function* sagas(): Generator<unknown, any, unknown> {
    const watchers = Object.values({
        ...orderSagas,
        ...certificatesSagas,
    }) as Saga[]
    yield* all(watchers.map((watcher) => fork(watcher)))
}

export default sagas
