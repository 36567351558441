import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { SignUtils } from '@dltru/dfa-sign'
import { closeMessage, openMessage, openNotification } from '@dltru/dfa-ui'

import {
    loadOrder,
    prepareTransaction,
    setError,
    setLoading,
    setOrder,
    signTransaction,
} from '@store/order/index'
import { orderSelectors } from '@store/order/selectors'
import { setIsLoadingTransactionDetails, setItemsTransactionDetails } from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'

import api from '@services/api'

function* loadOrderById({ payload }: ReturnType<typeof loadOrder>) {
    try {
        yield* put(setLoading(true))

        const { data, error } = yield* call(api.lib.getInvestOrderById, payload)

        if (error) {
            throw error
        }

        yield* put(setOrder(data))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось загрузить заявку на инвестирование',
        })
        yield* put(setError(error))
    } finally {
        yield* put(setLoading(false))
    }
}

function* handleTransactionSign({ payload }: ReturnType<typeof signTransaction>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingSign',
            message: 'Подписание транзакции',
        })

        yield* put(setIsLoadingTransactionDetails(true))

        const uuid = yield* select(orderSelectors.selectUuid)
        const transaction = yield* select(transactionDetailsSelectors.selectTransaction)

        if (!uuid || !transaction) {
            throw Error
        }

        const [sign] = yield SignUtils.gostAftSign(payload.sha1, transaction.Hash)
        const { error } = yield* call(api.lib.signInvestOrder, uuid, { sign })

        if (error) {
            throw Error
        }
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Операция прервана',
            description:
                'Мы не смогли списать средства. Проверьте, достаточно ли средств на лицевом счёте, после чего повторите попытку.',
        })
    } finally {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('loadingSign')
    }
}

function* handleTransactionPrepare({ payload }: ReturnType<typeof prepareTransaction>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPrepare',
            message: 'Формирование транзакции',
        })

        yield* put(setIsLoadingTransactionDetails(true))

        const uuid = yield* select(orderSelectors.selectUuid)
        if (!uuid) {
            throw Error
        }

        const { data, error } = yield* call(api.lib.prepareInvestOrder, uuid, payload)
        if (error || !data?.Transaction) {
            throw Error
        }

        yield* put(
            setItemsTransactionDetails({
                ...data.Transaction,
                json: JSON.parse(data.Transaction.json),
            }),
        )
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    } finally {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('loadingPrepare')
    }
}

export function* watchOrderSagas(): Generator<StrictEffect> {
    yield* takeLatest(loadOrder.type, loadOrderById)
    yield* takeLatest(prepareTransaction.type, handleTransactionPrepare)
    yield* takeLatest(signTransaction.type, handleTransactionSign)
}
