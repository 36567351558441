import { StrictEffect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'typed-redux-saga'

import { certificateSlice, mapCertificates } from '@store/certificates/index'
import { extractUserData } from '@store/helper'
import { orderSelectors } from '@store/order/selectors'

function* handleMapCertificates({
    payload: { allCertificates },
}: ReturnType<typeof mapCertificates>) {
    try {
        const certificatesFromOrder = yield* select(orderSelectors.selectCertificates)
        if (allCertificates?.length && certificatesFromOrder) {
            const mappedCerts = certificatesFromOrder.map((cert) => {
                const foundedCertificate = allCertificates?.find(
                    ({ thumbprint }) => thumbprint.toLowerCase() === cert.sha1.toLowerCase(),
                )
                if (!foundedCertificate) {
                    return null
                }
                return {
                    sha1: cert.sha1,
                    skid: cert.skid,
                    subject: foundedCertificate.subject,
                    expired_at: foundedCertificate.expired_at,
                    ...extractUserData(foundedCertificate.subject),
                }
            })
            yield* put(certificateSlice.actions.setData(mappedCerts))
        }
    } catch (error) {
        console.log(error)
    }
}

export function* watchCertificatesSagas(): Generator<StrictEffect> {
    yield* takeLatest(mapCertificates.type, handleMapCertificates)
}
