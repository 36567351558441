/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { ICertificate, UserCertificateType } from '@dltru/dfa-models'

interface IInitialState {
    items: ICertificate[] | null
}
const initialState: IInitialState = {
    items: null,
}
export const certificateSlice = createSlice({
    name: 'certificateSlice',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<any>) {
            state.items = action.payload
        },
        clearData(state) {
            state.items = null
        },
    },
})

export const mapCertificates =
    createAction<{ allCertificates: UserCertificateType[] }>('certificate/mapCerts')

export default certificateSlice.reducer
