import { AppState } from '@store/index'

const selectOrder = (state: AppState) => state.order.order
const selectUuid = (state: AppState) => state.order?.order?.UUID
const selectOrderLoading = (state: AppState) => state.order.loading
const selectCertificates = (state: AppState) => state.order?.order?.Skids

export const orderSelectors = {
    selectOrder,
    selectOrderLoading,
    selectUuid,
    selectCertificates,
}
