import { API } from '@dltru/api-request'

import { Agent } from './agent'

const BaseUrl = '/api'

const agent = Agent(BaseUrl)

const api = new API({ agent })

export default api
