import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import certificates from './certificates'
import order from './order'
import sagas from './sagas'
import transactionDetails from './sign'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
    order,
    transactionDetails,
    certificates,
})

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

sagaMiddleware.run(sagas)

export type AppStore = typeof store
export type AppState = ReturnType<AppStore['getState']>
export default store
