import { createAction, createSlice } from '@reduxjs/toolkit'

import {
    IInvestOrder,
    IInvestOrderPreparePayload,
    IInvestOrderSignPayload,
} from '@dltru/dfa-models'

interface IInitialState {
    loading: boolean
    error: string
    order: IInvestOrder | null
}

const initialState: IInitialState = {
    loading: false,
    error: '',
    order: null,
}

const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {
        setLoading: (state, { payload }) => ({
            ...state,
            loading: payload,
        }),
        setError: (state, { payload }) => ({
            ...state,
            error: payload,
        }),
        setOrder: (state, { payload }) => ({
            ...state,
            order: payload,
        }),
        dropState: () => {
            return initialState
        },
    },
})

export const loadOrder = createAction<string>('order/loadOrder')
export const prepareTransaction = createAction<IInvestOrderPreparePayload>('order/prepare')
export const signTransaction = createAction<IInvestOrderSignPayload>('order/sign')

export const { setLoading, dropState, setOrder, setError } = orderSlice.actions

export default orderSlice.reducer
