import { ProfileType } from '@dltru/dfa-models'

const extractProp = (from: string, what: string) => {
    let certName = ''

    const begin = from.indexOf(what)

    if (begin >= 0) {
        const end = from.indexOf(', ', begin)
        certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin)
    }

    return certName.replace(what, '')
}

export const extractUserData = (string: string) => {
    let result = {}
    if (string.includes('ОГРНИП=')) {
        result = {
            type: ProfileType.BUSN,
            ogrnip: extractProp(string, 'ОГРНИП='),
        }
    }
    if (string.includes('ОГРН=')) {
        result = {
            type: ProfileType.LEGL,
            ogrn: extractProp(string, 'ОГРН='),
            organizationName: extractProp(string, 'O='),
        }
    }
    if (string.includes('СНИЛС=')) {
        result = { type: ProfileType.PRSN, snils: extractProp(string, 'СНИЛС=') }
    }
    return {
        ...result,
        fullName: `${extractProp(string, 'SN=')} ${extractProp(string, 'G=')}`,
    }
}
