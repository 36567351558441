import axios, { AxiosInstance } from 'axios'

export const Agent = (baseURL?: string): AxiosInstance => {
    const headers = { 'Content-Type': 'application/json' }

    const _agent = axios.create({
        baseURL,
        headers,
        withCredentials: false,
    })

    _agent.interceptors.request.use((config) => {
        config.headers = {
            ...headers,
            ...config.headers,
        }

        return config
    })

    return _agent
}
